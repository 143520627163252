
import React, { useContext, useState, useEffect } from 'react';

import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import '../../assets/css/sb-admin-2.css';
import '../../vendor/datatables/dataTables.bootstrap4.css';
import AppContext from "../../context/AppContext";
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { message, Modal, notification } from 'antd';
import axios from 'axios';


export default function ProductsTable() {

    const [usuario] = useCookies(['usuarioadmi']);
    const [imageUrls, setImageUrls] = useState([]);
    const { active, api, setTitulo } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingenery, setLoadingenery] = useState(true);
    const fetchData = async () => {
        const result = await getData();

        setData(result);
    };



    const getData = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idusuario: usuario.usuarioadmi.id })
            };

            const response = await fetch(`${api}/api/productos/`, requestOptions);
            const result = await response.json();

            async function fetchCategorias() {


                const response = await fetch(`${api}/api/categorias/`, requestOptions);
                const data = await response.json();
                setCategorias(data);
            }

            setLoadingenery(false);


            fetchCategorias();
            const dataWithImageUrls = await Promise.all(
                result.map(async (item) => {

                    return item;
                })
            );

            return dataWithImageUrls;


        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setTitulo('Gestión de Productos');
        fetchData();
    }, []);

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
        });
    };

    const handleToggle = (productId, isActive, nombre) => async () => {
        Modal.confirm({
            title: `¿Estás seguro de ${isActive ? 'desactivar' : 'activar'} este producto "${nombre}"?`,
            okText: isActive ? 'Desactivar' : 'Activar',
            cancelText: 'Cancelar',
            okButtonProps: {
                style: { backgroundColor: isActive ? '#ff4d4f' : '#52c41a', borderColor: isActive ? '#ff4d4f' : '#52c41a' } // Cambia el color de fondo y borde
            },
            onOk: async () => {
                setLoading(true);
                try {
                    // Notifica al backend
                    await axios.post(`${api}/api/productos/toggle`, { id: productId, idusuario: usuario.usuarioadmi.id, isActive: !isActive });
                    openNotificationWithIcon('success', 'Operación Exitosa', `Producto ${!isActive ? 'activado' : 'desactivado'} con éxito`);

                    // Actualiza el estado del producto en la tabla localmente
                    setData(prevProducts => prevProducts.map(product =>
                        product.id === productId ? { ...product, activo: !isActive } : product
                    ));

                } catch (error) {
                    openNotificationWithIcon('error', 'Error', 'Ocurrió un error al intentar cambiar el producto');
                } finally {
                    setLoading(false);
                }
            },
            onCancel() {
                // Cancelar acción
            },
        });
    };


    const columns = React.useMemo(
        () => [{
            Header: 'Id',
            accessor: 'id',
        }, {
            Header: 'Activo',
            Cell: ({ row }) => (
                <div className="col-sm-10">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                            checked={row.original.activo}
                            desabled={loading}
                            onChange={handleToggle(row.original.id, row.original.activo, row.original.nombre)} />
                    </div>
                </div>
            )
        },
        {
            Header: 'Nombre',
            accessor: 'nombre',
        },
        {
            Header: 'Imagen',
            Cell: ({ row }) => {

                return <img alt={row.original.nombre} className="img" src={row.original.img}
                />
            },
        },

        {
            Header: 'Ingredientes',
            accessor: 'ingredientes',
        },
        {
            Header: 'Precio',
            accessor: 'precio',
        },
        {
            Header: 'Stock',
            accessor: 'stock',
        },
        {
            Header: 'Categoria',
            Cell: ({ row }) => {
                const categoria = categorias.find(cate => cate.id === row.original.idcategoria.id);
                return categoria?.nombre;
            }
        },
        {
            Header: 'Acciones',
            // Cell: ({ row }) => (
            //     <i className="bi bi-trash3-fill" onClick={() => eliminarusuario(row.original.id)}></i>
            // ),
            Cell: ({ row }) => (
                <Link to={'/Admin/editarproducto/' + row.original.id} ><i className="bi bi-pencil-square"></i></Link>
            ),
        },


        ],
        [imageUrls, categorias]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
        setGlobalFilter,
        searchTerm,
        setSearchTerm,
        pageOptions
    } = useTable(
        {
            // eslint-disable-next-line no-undef
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );




    const { globalFilter, pageIndex, pageSize } = state;

    const [filterInput, setFilterInput] = useState('');

    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setGlobalFilter(value);
        setFilterInput(value);
    };


    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
    };

    React.useEffect(() => {
        setGlobalFilter(filterInput);
    }, [filterInput, setGlobalFilter]);


    return (


        <main id="main" className={active === 'active' ? 'main active' : 'main'}>

            {/*    <h2 className="h3 mb-2 text-gray-800">Gestión de Productos</h2> <div className="endFlex">
              </div>*/}

            <div className="card shadow mb-4">

                <div className="card-header py-3 flex">
                    <label className='pr-4 flex center align-items-baseline'>
                        Mostrar{' '}
                        <select disabled={data.length < pageSize} value={pageSize} onChange={handlePageSizeChange} className="custom-select custom-select-sm form-control form-control-sm ml-2 mr-2">

                            {[5, 10, 20, 30, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>{' '}
                        {data.length > 0 && (
                            <span className="ml-2" style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>
                                ({data.length} Totales)
                            </span>
                        )}
                    </label>

                    <div className="col-sm-12 col-md-6 grid">

                        <div id="usuarios_filter" className="dataTables_filter">
                            <label>Buscar:
                                <input type="search" className="form-control form-control-sm" placeholder="" aria-controls="usuarios" value={filterInput}
                                    onChange={handleFilterChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">





                    </div>
                    {loadingenery ? (
                        <div className="center">  <div className=" spinner"></div></div>

                    ) :
                        <>   <table {...getTableProps()} className="table table-bordered">
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={
                                                    column.isSorted
                                                        ? column.isSortedDesc
                                                            ? 'sort-desc'
                                                            : 'sort-asc'
                                                        : ''
                                                }
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>



                            <div className="col-sm-12 col-md-7">
                                <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                    <ul className="pagination">
                                        <li className={(pageIndex + 1) !== 1 ? "paginate_button page-item previous" : "paginate_button page-item previous disabled"} disabled={!canPreviousPage}>
                                            <button aria-controls="dataTable" data-dt-idx="0" tabIndex="0" className="page-link" onClick={() => previousPage()} >Anterior</button></li>


                                        <li className="paginate_button page-item page-link">{pageIndex + 1} de {pageOptions.length}</li>




                                        <li className="paginate_button page-item next" id="dataTable_next"><a onClick={() => nextPage()} aria-controls="dataTable" data-dt-idx="7" tabIndex="0" className="page-link">Próximo</a></li>
                                    </ul>
                                </div>
                            </div></>}
                </div>
            </div>
            <a class="back-to-topAdmi d-flex align-items-center justify-content-center active " >
                <Link to="/Admin/productoalta" className=" btn btn-primary rounded-circle w-100 p-0">
                <i class="bi bi-plus-circle-dotted"></i>
                </Link>
            </a>

        </main>


    );
}
