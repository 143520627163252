import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useHistory, redirect, useNavigate } from "react-router-dom";
import AppContext from '../context/AppContext';
import { useCookies } from 'react-cookie';
import { CloseCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';


export default function ProductoItem({ props }) {

  const { addToCart, cart, deleteCartById, restToCart, modo, setClick, deleteCart, pedidos, api, setTotal, table, setPago } = useContext(AppContext);
  const [usuario, setUsuario] = useCookies(['usuario']);
  const [cookies, setCookie, getCookie] = useCookies(['cliente']);
  const [mesero, setMesero] = useCookies(["mesero"]);
  const [isLoading, setIsLoading] = useState(false);
  const [ok, setOk] = useState(0);
  const navigate = useNavigate();
  var modal = document.getElementById('PedidoModal');
  const [selectedOptionPago, setSelectedOptionPago] = useState([]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault(); // Evita que la página vuelva a la anterior
      const modal = document.getElementById(`carrito`);

      if (modal.classList.contains('active')) {
        modal.classList.remove('active');

        setClick();
      }

    };

    // win9dow.history.pushState(null, '', window.location.href); // Agrega una nueva entrada de historial para evitar volver atrás
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const hideModal = () => {
    // const modal = document.querySelector('.modal'); // obtener el modal
    const backdrop = document.querySelector('.modal-backdrop'); // obtener el backdrop
    if (modal) {

      modal.classList.remove('show'); // ocultar el modal
      modal.classList.remove('hide');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');

      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
    }
    if (backdrop) {
      backdrop.remove(); // eliminar el backdrop
    }
  };


  const [nombre, setNombre] = useState(cookies.cliente?.nombre || '');
  const [email, setEmail] = useState(cookies.cliente?.email || '');
  const [telefono, setTelefono] = useState(cookies.cliente?.telefono || '');
  const [activo, setActivo] = useState(cookies.cliente?.activo || '');
  const [comentario, setComentario] = useState(cookies.cliente?.comentario || '');
  const [direccion, setDireccion] = useState(cookies.cliente?.direcciones?.find(dir => dir.activa) || '');
  const [cupon, setCupon] = useState('');

  const sumaTotal = () => {
  
    const reducer = (accumalator, currentValue) => {
      const extraCost = currentValue.extra?.reduce(
        (extraAcc, extraItem) => extraAcc + extraItem.costo, 
        0
      ) || 0;
      
      return accumalator + (currentValue.price + extraCost) * currentValue.qty;
    };
    const sum = cart.card.reduce(reducer, 0)
    return sum;
  }

  let qty = 0;

  const revomeProdu = produ => {
    deleteCartById(produ);

  }

  const handleOptionChange = (opcion) => {
    setSelectedOptionPago(opcion);
  };

  const handleInputChange = (event) => {
    setCupon(event.target.value); // Actualizar el estado del cupón cuando cambie el valor del input
  };

  const promociones = async () => {

    const data = {
      id: usuario.usuario.id,
      codigo: cupon,
      monto: sumaTotal()
    }

    const response = await fetch(`${api}/api/promocion/verificar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(data),
    });
    const datas = await response.status;

    if (datas === 200) {

    }
  }


  const addCliente = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const user = {
      nombre: formData.get('nombre'),
      email: formData.get('email'),
      telefono: formData.get('telefono'),
      subscrito: formData.get('subscrito'),
    }

    const cookieActual = cookies.cliente;

    const nuevaCookie = {
      ...user,
      ...cookieActual,
    };

    setCookie('cliente', nuevaCookie, { path: '/', maxAge: 2592000 });
    //,  consumoTotal: consumoTotal 

    setIsLoading(true);
    try {
      const data = {
        cliente: { nombre: user.nombre, email: user.email, telefono: user.telefono, subscricion: user.subscrito, direccion: user.direccion, pisodepto: user.piso ? user.piso.trim() : '', observacion: user.observacion, latitud: user.latitud, longitud: user.longitud },
      };


      const response = await fetch(`${api}/api/pedidos/cliente`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });
      const datas = await response.status;

      if (datas === 200) {
        setOk(datas)
        const modal = document.getElementById(`carrito`);

        if (modal?.classList.contains('active')) {
          modal.classList.remove('active');

          setClick();
        }

        updateProduct(selectedOptionPago, event);
        hideModal(); // cerrar el modal
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // desactivar el indicador de carga
    }
  }
  const updateProduct = async (metapago, event) => {
    event.preventDefault(); // Evita que se envíe la solicitud por defecto del navegador
    setIsLoading(true); // Activa el indicador de carga
    if (modal?.classList.contains('active')) {
      modal.classList.remove('active');

    }
    setClick();
    setPago(metapago)
    setTotal(sumaTotal);
    const destino = `/${usuario.usuario.local}/pago`;

    navigate(destino);

    setIsLoading(false); // Desactiva el indicador de carga

  };

  const updateProductMozo = async ( metapago, consumoTotal, event) => {
    event.preventDefault(); // Evita que se envíe la solicitud por defecto del navegador
    setIsLoading(true); // Activa el indicador de carga

    try {
      // Construye el cuerpo de la solicitud
      const clienteActivo = cookies.cliente?.direcciones?.find(direccion => direccion.activa) || {};

      const data = {

        pedidos: {
          consumototal: consumoTotal,
          comentario: comentario || "", // Validación de valor predeterminado
          pago: metapago,
          modo: modo, // Valor pordefecto si 'modo' es opcional
          mesero: mesero?.mesero.mesero || "",
          idmesero: mesero?.mesero.id,
          pedidoproducto: cart.card.map(({ id, qty, nombre, price, comentario }) => ({
            producto: id,
            cantidad: qty,
            aclaracion: comentario,
          })),
        },

        cliente: {

          nombre: cookies.cliente?.nombre || nombre,
          email: cookies.cliente?.email || "",
          telefono: cookies.cliente?.telefono || telefono,
          subscricion: cookies.cliente?.subscrito || false,
          direccion: clienteActivo.direccion || "",
          pisodepto: clienteActivo.piso?.trim() || "",
          observacion: clienteActivo.observacion || "",
          latitud: clienteActivo.latitud || "",
          longitud: clienteActivo.longitud || ""
        }
      };

      // Realiza la solicitud POST
      const response = await fetch(`${api}/api/pedidos/${usuario.usuario.id}/${table}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Manejo de respuesta
      const status = await response.status;
      const pedido = await response.json();
      if (status === 200) {
        // Verifica si el modal está activo y lo desactiva
        const modal = document.getElementById(`carrito`);

        if (modal?.classList.contains('active')) {
          modal.classList.remove('active');
    
        }
        setClick();
        hideModal();
        // Actualiza el estado en función de la respuesta
        pedidos(status);
        deleteCart();
        setOk(status);
        setTotal(sumaTotal);

        // Navegación según si hay un mesero o no
        const destino =  `/${usuario.usuario.local}/pedidos`
          //: `/${usuario.usuario.local}/pago`;

        navigate(destino, { state: { data, pedido } });
      } else {
        notification.open({
          message: "Ocurrio un error al crear el producto 😢",
          description: "Intentalo nuevamente o comunicate con un personal.",
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
          duration: 0,
        });
      }
    } catch (error) {
      console.error("Error al actualizar el producto:", error);
      // Aquí puedes agregar lógica adicional para manejar errores, como mostrar una notificación
    } finally {
      setIsLoading(false); // Desactiva el indicador de carga
    }
  };

  return (
    <>
      {
        cart.card.map((props) => (

          <li key={props.id} id="cart" className="confirm">
            <div className="photo"><img src={props.img} alt="" loading="lazy" /></div>
            <div className="boxes" >
              <div className="detalles name">
                <h6>{props.nombre}</h6>
              </div>
              <div className="detalles interno">
                <div className='qty'>
                  <span onClick={() => {
                    // setShowError(true);
                    restToCart(props, -1);
                  }}
                    disabled={qty - props.qty <= 0 ? true : false}
                    className="material-symbols-outlined">
                    <i className="bi bi-dash-circle"></i>
                  </span>

                  <span id="cantidad">{props.qty} </span>

                  {/* onClick={() => addProduct(-1)}
                         disabled={qty === initial ? true : null} */}

                  <span className="material-symbols-outlined"
                    onClick={() => {
                      if (props.qty === props.stock) return;
                      addToCart(props, 1);


                    }} disabled={props.stock - qty === 0 ? true : false}>
                    <i className="bi bi-plus-circle"></i>
                  </span>

                </div>

                <h6 className="precio">${((props.price + 
                (props.extra?.reduce((extraAcc, extraItem) => extraAcc + extraItem.costo, 0) || 0)) 
                * props.qty).toFixed(2)}</h6>
                <div className="delete" onClick={() => revomeProdu(props)} >
                  <span className="material-symbols-outlined" translate="no">
                    <i className="bi bi-trash"></i>
                  </span>
                </div>
              </div>
              { //showError && <p className='error-message'>No se puede restar más del stock disponible</p>
              }
            </div>

          </li>))}
      {/* <li className='center'>   <div className="accordion accordion-flush" id="accordionFlushExample">
            <br></br>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed pad10" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Agregar comentario
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body"><textarea className="form-control" name="message" rows="3" placeholder="Comentario" onChange={(e) => setComentario(e.target.value)}></textarea></div>
                      </div>
                    </div>
                  </div> </li> */}
      <ul>

        <li id="comprar">
          <h4 className="subtotal ">Subtotal</h4>
          <h4 className="subtotal cartTotal">${sumaTotal().toFixed(2).slice(0, -3)}</h4>
          <h6 className="decimal">{sumaTotal().toFixed(2).slice(-2)}</h6>
          <div id="comprar" className="duo">
         {  /* <div className="grid  center wid50">
              <span className=""> <input type="text" placeholder="Ingresa tu Cupón" className='cupon' value={cupon} onChange={handleInputChange} />
                <button className="buttonMenu" onClick={() => promociones()}>Agregar</button></span>
            </div>*/}
            {(mesero?.mesero) ?
              <button className="normal end second mt-2" id="add" data-bs-toggle="modal" data-bs-target='#MozoModal' >
                <h3>Comandar</h3>
              </button> :
              (cookies.cliente && cookies.cliente.nombre) ?
                isLoading ? <button className="normal end second"><div className="loading d-block"></div></button> :

                  modo === "Delivery" ?
                    <button className="normal end second" id="add" data-bs-toggle="modal" data-bs-target='#DeliveryModal'>
                      <h3>Elegir Forma de Pago</h3>
                    </button> :

                    <button type="button" className="normal end"><div className="dropup-center dropup">
                      <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Elegir Forma de Pago</h3>
                      <ul className="dropdown-menu metopago">
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => updateProduct('EFÉCTIVO',  event)}><i class="bi bi-cash-stack"></i>Eféctivo</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => updateProduct('TARJETA', event)}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => updateProduct('TRANSFERENCIA', event)}><i class="bi bi-bank2"></i>Transferencia</button></li>
                        {/* <li> <button className="dropdown-item"   onClick={() => setSelectedOptionPago({  pago: 'DEBI', idusuario: usuario.usuario.id})}>Débito</ button></li> */}
                        <li><hr className="dropdown-divider"></hr></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => updateProduct('MEPG', event)}><svg width="27" height="27" stroke="black" strokeWidth="2" viewBox="0 0 48 48">
                          <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" />
                          <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" />
                          <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" />
                          <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</ button>
                        </li>
                      </ul>
                    </div>
                    </button>
                : <button className="normal end second" id="add" data-bs-toggle="modal" data-bs-target='#PedidoModal'>
                  <h3>Continuar</h3>
                </button>}
            {/*  <button className="normal end" id="add" onClick={setClick} >
              <h4>Volver a Menú</h4>
            </button> */}



          </div>
        </li></ul>

      <div className="modal fade" id={`PedidoModal`} tabIndex="-1" aria-labelledby={`PedidoModal`}  >
        <div className="modal-dialog">
          <div className="modal-content modal-contentMenu">
            <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
            <div className="modal-body modal-bodyMenu">

              <form onSubmit={(event) => addCliente(event)}>
                <div className="row">

                  <h5 className='mb-4 center'>Datos para mejorar tu experiencia &#128522;</h5>

                  {modo === "Delivery" &&
                    <div className=" form-group magtop4">

                      <input type="text" name="direccion" readOnly required className="form-control ubic textCenter" value={direccion.direccion} onChange={(e) => setActivo(e.target.direccion)} />

                      <div className="my-3">
                        <input type="text" name="piso" readOnly required className="form-control ubic textCenter" value={direccion.piso} onChange={(e) => setActivo(e.target.piso)} />
                        <input type="text" name="observacion" readOnly reaplaceholder="(Casa, Oficina, Obs..." className="form-control ubic textCenter" value={direccion.observacion} onChange={(e) => setActivo(e.target.observacion)} />
                      </div>
                    </div>}

                  <div className=" form-group">


                    <input type="text" name="nombre" className="form-control" id="nombre" placeholder="Tu Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                  </div>
                </div>
                <div className=" form-group">
                  <input type="number" className="form-control" name="telefono" id="celular4" placeholder="Tu Celular" value={telefono} pattern="[0-9]{1,10}" onChange={(e) => setTelefono(e.target.value)} required />
                </div>
                <div className="form-group mt-3">
                  <input type="email" className="form-control" name="email" id="email1" placeholder="Tu Email (opcional)" value={email} onChange={(e) => setEmail(e.target.value)} />

                </div>

                {/* <div className="form-group mt-3">
                  <textarea className="form-control" name="comentario" rows="2" placeholder="Observación" ></textarea>
                </div> */}
                <div className="my-3">

                  <input type="checkbox" name="subscrito" value={activo} onChange={(e) => setActivo(e.target.checked)} />
                  <h6>Subscribirme para recibir promociones</h6>

                </div>

                {/* <div >
                <div className="loading d-block"></div>
                <div className="error-message"></div>
              </div> */}

                {ok === 200 ? <div className='center' data-bs-dismiss="modal"><i className="bi bi-check-circle-fill"></i></div> :
                  isLoading ? <button className="normal end second"><div className="loading d-block"></div></button> :
                    <div className="normal end center"><div className="dropup-center dropup">
                      <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Elegir Forma de Pago</h3>
                      <ul className="dropdown-menu metopago">
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('EFÉCTIVO')}><i class="bi bi-cash-stack"></i>Eféctivo</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('TARJETA')}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('TRANSFERENCIA')}><i class="bi bi-bank2"></i>Transferencia</button></li>
                        {/* <li> <button className="dropdown-item"   onClick={() => setSelectedOptionPago({  pago: 'DEBI', idusuario: usuario.usuario.id})}>Débito</ button></li> */}
                        <li><hr className="dropdown-divider"></hr></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('MEPG')}><svg width="27" height="27" stroke="black" strokeWidth="2" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</ button>
                        </li>
                      </ul>
                    </div>
                    </div>
                }
              </form>

            </div>

          </div></div>

      </div>

      <div className="modal fade" id={`MozoModal`} tabIndex="-1" aria-labelledby={`MozoModal`}  >
        <div className="modal-dialog">
          <div className="modal-content modal-contentMenu">
            <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
            <div className="modal-body modal-bodyMenu">

              <form onSubmit={(event) => updateProductMozo(selectedOptionPago, sumaTotal(), event)}>
                <div className="row">

                  <h5 className='mb-4 center'>Datos del Cliente o Nombre del Grupo &#128522;</h5>



                  <div className=" form-group">


                    <input type="text" name="nombre" className="form-control" id="nombre" placeholder="Nombre Cliente o del Grupo" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                  </div>
                </div>
                <div className=" form-group">
                  <input type="number" className="form-control" name="telefono" id="phone" placeholder="Un Celular" value={telefono} pattern="[0-9]{1,10}" onChange={(e) => setTelefono(e.target.value)} />
                </div>

                <div className="form-group mt-3">
                  <textarea className="form-control" name="comentario" rows="1" placeholder="Observación" value={comentario} onChange={(e) => setComentario(e.target.value)}></textarea>
                </div>

                {/* <div >
                <div className="loading d-block"></div>
                <div className="error-message"></div>
              </div> */}

                {ok === 200 ? <div className='center' data-bs-dismiss="modal"><i className="bi bi-check-circle-fill"></i></div> :
                  isLoading ? <button className="normal end second"><div className="loading d-block"></div></button> :
                    <div className="normal end center"><div className="dropup-center dropup">
                      <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Elegir Forma de Pago</h3>
                      <ul className="dropdown-menu metopago">
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => handleOptionChange('EFÉCTIVO', sumaTotal(), event)}><i class="bi bi-cash-stack"></i>Eféctivo</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => handleOptionChange('TARJETA', sumaTotal(), event)}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => handleOptionChange('TRANSFERENCIA', sumaTotal(), event)}><i class="bi bi-bank2"></i>Transferencia</button></li>
                        {/* <li> <button className="dropdown-item"   onClick={() => setSelectedOptionPago({  pago: 'DEBI', idusuario: usuario.usuario.id})}>Débito</ button></li> */}
                        <li><hr className="dropdown-divider"></hr></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={(event) => handleOptionChange('MEPG', sumaTotal(), event)}><svg width="27" height="27" stroke="black" strokeWidth="2" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</ button>
                        </li>
                      </ul>
                    </div>
                    </div>
                }
              </form>

            </div>

          </div></div>

      </div>


      <div className="modal fade" id={`DeliveryModal`} tabIndex="-1" aria-labelledby={`DeliveryModal`}  >
        <div className="modal-dialog">
          <div className="modal-content modal-contentMenu">
            <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
            <div className="modal-body modal-bodyMenu">

              <form onSubmit={(event) => addCliente(event)}>
                <div className="row textCenter">
                  <h5>Confirmar Datos - Delivery</h5>
                </div>
                <div className=" form-group magtop4">

                  <input type="text" name="direccion" readOnly required className="form-control ubic textCenter" value={direccion.direccion} onChange={(e) => setActivo(e.target.direccion)} />

                  <div className="my-3">
                    <input type="text" name="piso" readOnly required className="form-control ubic textCenter" value={direccion.piso} onChange={(e) => setActivo(e.target.piso)} />
                    <input type="text" name="observacion" readOnly reaplaceholder="(Casa, Oficina, Obs..." className="form-control ubic textCenter" value={direccion.observacion} onChange={(e) => setActivo(e.target.observacion)} />
                  </div>
                </div>


                <div className=" form-group">

                  <input type="text" readOnly name="nombre" className="form-control ubic textCenter" id="nombre" placeholder="Tu Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} required />

                  <div className="my-3">
                    <input type="number" readOnly className="form-control ubic textCenter grid" name="telefono" id="celular1" placeholder="Tu Celular" value={telefono} pattern="[0-9]{1,10}" onChange={(e) => setTelefono(e.target.value)} required />
                    {/*   <input type="email" readOnly className="form-control ubic textCenter mar4" name="email" id="email" placeholder="Tu Email (opcional)" value={email} onChange={(e) => setEmail(e.target.value)} />
*/}
                  </div>

                </div>
                {/*  */}
                {/* <div className="form-group mt-3">
                  <textarea className="form-control" name="comentario" rows="2" placeholder="Observación" ></textarea>
                </div> */}


                {/* <div >
                <div className="loading d-block"></div>
                <div className="error-message"></div>
              </div> */}

                {ok === 200 ? <div className='center' data-bs-dismiss="modal"><i className="bi bi-check-circle-fill"></i></div> :
                  isLoading ? <button className="normal end second"><div className="loading d-block"></div></button> :
                    <div className="normal end"><div className="dropup-center dropup">
                      <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Elegir Forma de Pago</h3>
                      <ul className="dropdown-menu metopago">
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('EFÉCTIVO')}><i class="bi bi-cash-stack"></i>Eféctivo</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('TARJETA')}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</button></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('TRANSFERENCIA')}><i class="bi bi-bank2"></i>Transferencia</button></li>
                        {/* <li> <button className="dropdown-item"   onClick={() => setSelectedOptionPago({  pago: 'DEBI', idusuario: usuario.usuario.id})}>Débito</ button></li> */}
                        <li><hr className="dropdown-divider"></hr></li>
                        <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('MEPG')}><svg width="27" height="27" stroke="black" strokeWidth="2" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</ button>
                        </li>
                      </ul>
                    </div>
                    </div>
                }
              </form>

            </div>

          </div></div>

      </div>
    </>
  )

}