import React, { useContext, useEffect, useState } from 'react';
import productList from '../data/productList';
import Productos from "../view/products";
import AppContext from '../context/AppContext';
import ProductoItem from "../view/productItem";
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import soundFile from "../assets/ring1.mp3";



export default function Menu() {

  const { api, cart, setModo, link, setClick, table} = useContext(AppContext);
  const [cant, setcant] = useState(cart.card.length);
  const { parametro } = useParams();
  const [mesero, setMesero] = useCookies(["mesero"]);
  const [usuario] = useCookies(['usuario']);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoria, setCategoria] = useState("todos");
  const [elementosFiltrados, setElementosFiltrados] = useState([]);
  let category = " "

  const scrollToTop = () => {
    console.log('scrollToTop called');
    debugger
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  const fetchData = async () => {
    const result = await getData();

    setData(result);
    setIsLoading(true)
  };

  
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value.toLowerCase()); // Actualiza el estado con el valor del input en minúsculas
  };

  useEffect(() => {
  const productosFiltrados = data.filter((product) => 
    product.nombre.toLowerCase().includes(searchValue)
  );

  setElementosFiltrados(productosFiltrados)
}, [searchValue]);

  const getData = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idusuario: usuario.usuario.id })
      };

      const response = await fetch(`${api}/api/productos/menu`, requestOptions);
      const result = await response.json();
      
      async function fetchCategorias() {


        const response = await fetch(`${api}/api/categorias/`, requestOptions);
        const data = await response.json();
        
        setCategorias(data.map((categoria => ({ nombre: categoria.nombre, id: categoria.id }))));
    
  
      }



      fetchCategorias();
      const dataWithImageUrls = await Promise.all(
        result.map(async (item) => {

          return item;
        })
      );

      return dataWithImageUrls;

    } catch (error) {
      window.location.replace('/error')
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);

    const modalElement = document.getElementById('exampleModal-MESA');
    const modal = new window.bootstrap.Modal(modalElement);

    // Limpia cualquier modal-backdrop existente
    const removeBackdrops = () => {
        document.querySelectorAll('.modal-backdrop').forEach((backdrop) => {
            backdrop.parentNode.removeChild(backdrop);
        });
    };
    switch (parametro) {

      case "takeAway":
        setModo("TAKE AWAY");
        break;
      case "delivery":
        setModo("Delivery");
        break;
      default:
        setModo("Local");
        // Muestra el modal
        if(!table){ 
          removeBackdrops(); 
           modal.show()
        }else{
          removeBackdrops(); 
        }
        break;
    }


  }, [table]);



  const onChangeLinkMenu = () => {
    setClick();
  }

  function addToCant(cant) {
    return setcant;
  }



  const sumaTotal = () => {
    const reducer = (accumalator, currentValue) => {
      const extraCost = currentValue.extra?.reduce(
        (extraAcc, extraItem) => extraAcc + extraItem.costo, 
        0
      ) || 0;
      
      return accumalator + (currentValue.price + extraCost) * currentValue.qty;
    };
    
    const sum = cart.card.reduce(reducer, 0)
    return sum;
  }

  useEffect(() => {
    const handleActiveClass = () => {
      // Elimina la clase 'filter-active' de todos los elementos
      document.querySelectorAll('.filter-active').forEach(item => {
        item.classList.remove('filter-active');
      });
  
      // Obtiene el elemento 'li' correspondiente a la categoría seleccionada
      const activeElement = document.querySelector(`li[data-id="${categoria}"]`);
  
      // Asegura que el elemento existe antes de acceder a 'classList'
      if (activeElement) {
        activeElement.classList.add('filter-active');
      }
    };
  

    handleActiveClass();

    // Filtra los elementos según la categoría seleccionada
    const filteredElements = data.filter(
      elemento => categoria === "todos" || elemento.idcategoria.id === categoria
    );

    filteredElements.sort((a, b) => a.idcategoria - b.idcategoria);

    setElementosFiltrados(filteredElements);
  }, [categoria, data]);

  return (

    <section id="menu" className=" sectionMenu menu section-bg">

      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Menú</h2>
          <p>Selecciona tu Menú</p>
        </div>

        {isLoading ?
          <>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">

                <ul id="menu-flters">

                  {categorias.map(props =>
                  <li   key={props.id}
                  data-id={props.id}
                  className={props.id === categoria ? "filter-active" : ""}
                  onClick={() => setCategoria(props.id)}>{props.nombre}</li>)}
                  <li data-id="todos" className={categoria === "todos" ? "filter-active" : ""} onClick={() => setCategoria("todos")}>Todos</li>
                </ul>
              </div>
            </div>

            <div className="box position-absolute  mt-2 mb-4">
                <form name="search"  onSubmit={(e) => e.preventDefault()}>
                    <input type="text"  placeholder="Buscar" className="input" name="txt" 
                      value={searchValue}
                      onChange={handleSearchChange}/>
                  <i className="bi bi-search"></i>
                </form>
            </div>


            <div id="pfilter" className="row menu-container mt-4" data-aos="fade-up" data-aos-delay="200">
              {elementosFiltrados.map((product => {
                categorias.map(categ =>
                  categ.id === product.idcategoria ? category = categ.nombre : " ")
                return <Productos require img={product.img} categoria={category} nombre={product.nombre} price={product.precio} ingredients={product.ingredientes} ingrediente={product.ingrediente} id={product.id} adicionales={product.adicionales}></Productos>;


              })
              )}

            </div>
          </> : <div id="preloader"></div>}
      </div>
      {cart.card.length > 0 ?

        <div id='inf'>
          <button className='total' onClick={onChangeLinkMenu}>   <p className="subtotal pedido">Ver Pedido ({cart.card.length})</p><strong />
            <p className="subtotal cartTotal pedido">$ {sumaTotal().toFixed(2)}</p></button>
        </div> : null}


      <div id="mobile">
        <li><span id="shopMobile"><span className="material-icons-outlined">shopping_bag</span></span></li>
        <li> <span id="bar" className="material-icons-outlined">
          subject
        </span></li>

      </div>
      <div id="context">
        <ul id="carrito" className={link ? "active" : ""}>
          <li className="cabezal">
            <span id="closeCar" onClick={onChangeLinkMenu} ><span translate="no" className="material-icons-outlined">
              close
            </span></span> <span href="" id="title">
            {  mesero && mesero.mesero ? <h4>Pedido - Mesa <strong>{table}</strong>  </h4> : <h4>Mi Pedido</h4>}
            </span> <span ></span>
          </li>

          <ul id="pedido" >
            <ProductoItem></ProductoItem>
          </ul>

        </ul>
        {/* <div className={link ? "opacar visible" : "opacar "}></div> */}
        <a onClick={scrollToTop}  className="back-to-top d-flex align-items-center justify-content-center active"><i className="bi bi-arrow-up-short"></i></a>
      </div>
      {/*  <div id="preloader"></div>*/}
    </section>


  )
}