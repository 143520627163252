import { Alert } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import AppContext from "../../context/AppContext";

export default function Register(){
  const [registroExitoso, setRegistroExitoso] = useState();
  const [data, setData] = useState(false);
  const [showAlert, setShowAlert] = useState();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true); 
  const [passwordOk, setPasswordOk] = useState();
  const { api } = useContext(AppContext);

  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,12}$/;

  
  function validatePassword(password) {
      return passwordRegex.test(password);
    } 


  function handleSubmit(event) {
    event.preventDefault();
  
    //const formData = new FormData(event.target);
   
  
    if (password === password2 && validatePassword(password)) {
      setPasswordsMatch(true);
      handleRegistration(event);
      setPasswordOk(true)
     
    } else {
      //setShowAlert(true);
      setPasswordsMatch(false);
      setPasswordOk(false)
    }
  }
 
 const handleRegistration = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
       
       const user = {
          nombre: formData.get('nombre'),
          email: formData.get('email'),
          local: formData.get('local'),
          password: formData.get('password')
        }  
        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(user),
          headers: {
            'Content-Type': 'application/json'
          }
        };
        const response = await fetch(`${api}/api/admin/confirmar`, requestOptions);
        const data = await response.status;
        console.log(data);
        setData(data);
        // Si la respuesta indica que el registro fue exitoso,
        // actualizamos la variable de estado para mostrar el mensaje.
        if (data === 200) {

          setRegistroExitoso(true);
          event.target.reset();
          Navigate(`/Login`);
        } else{
          setRegistroExitoso(false);
        }
      };

    return(

        <main>
          { registroExitoso === false ? 
          <div className="alert alert-danger" role="alert">
          ¡Registro Falló! {data}
        </div> : <></>
    }   {showAlert && (
      <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
        Las contraseñas no coinciden.
      </Alert>
    )}
        <div className="container">
    
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
    
    
                  <div className="card mb-3">
    
                    <div className="card-body">
    
                      <div className="pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">Crear Cuenta</h5>
                        <p className="text-center small">Confirma tus datos</p>
                      </div>
    
                      <form className="row g-3 needs-validation" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label for="yourName" className="form-label">Nombre y Apellido</label>
                          <input type="text" name="nombre" className="form-control" id="yourName" required/>
                          <div className="invalid-feedback">Por favor, ingresa tu nombre!</div>
                          {/* <label for="yourName" className="form-label">Apellido</label>
                          <input type="text" name="apellido" className="form-control" id="yourName" required/>
                          <div className="invalid-feedback">Por favor, ingresa tu apellido!</div> */}
                        </div>
    
                        <div className="col-12">
                          <label for="yourEmail" className="form-label">Email</label>
                          <input type="email" name="email" className="form-control" id="yourEmail"autoComplete="username"  required/>
                          <div className="invalid-feedback">Por favor, ingresa tu Email!</div>
                        </div>
    
                        <div className="col-12">
                          <label for="yourUsername" className="form-label">Local</label>
                          <div className="input-group has-validation">
                            {/* <span className="input-group-text" id="inputGroupPrepend">@</span> */}
                            <input type="text" name="local" className="form-control" id="yourUsername" required />
                            <div className="invalid-feedback">Por favor, ingresa tu Local.</div>
                          </div>
                        </div>
    
                        <div className="col-12">
                          <label for="yourPassword" className="form-label">Contraseña</label>
                          <input type="password" name="password" className="form-control" id="yourPassword" autoComplete="new-password"  onChange={(e) => setPassword(e.target.value)}/>
                          <div className="invalid-feedback">Por favor, ingresa la contraseña!</div>
                          {passwordOk === false && <p className="text-danger">Las contraseñas no cumple con al menos una letra (mayúscula o minúscula), un dígito o la longitud mínima de 6 caracteres.</p>}
                          
                        </div>
                        <div className="col-12">
                          <label for="yourPassword" className="form-label">Repetir Contraseña</label>
                          <input type="password" name="password2" className="form-control" id="yourPassword2" required autoComplete="new-password" onChange={(e) => setPassword2(e.target.value)}/>
                          <div className="invalid-feedback">Por favor, ingresa la contraseña!</div>
                          {!passwordsMatch && <p className="text-danger">Las contraseñas no coinciden.</p>}
                        </div>
                        <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" name="terms" type="checkbox" value=""  id="acceptTerms" required/>
                            <label className="form-check-label" for="acceptTerms">Acepta los <a >terminos y condiciones</a></label>
                            <div className="invalid-feedback">Debes agregar antes de continuar.</div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button className="btn btn-primary w-100" type="submit"  >Registrame</button>
                        </div>
                        <div className="col-12">
                          <p className="small mb-0">¿Ya tienes un usuario? <Link to="/Admin/login">Ingresa aquí</Link></p>
                        </div>
                      </form>
    
                    </div>
                  </div>
    
     
                </div>
              </div>
            </div>
    
          </section>
    
        </div>
      </main>
    
    )
}