import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/AppContext";
import { useCookies } from "react-cookie";

export default function Aside() {
  const {active, setActive, toggleActive } = useContext(AppContext);
  const [usuario] = useCookies(['usuarioadmi']);
  const whatsappLink = `https://wa.me/+549${usuario?.usuarioadmi?.whatsapp}`;
  const maxWidth = 768; // Por ejemplo, 768px

  // Comprobamos si el ancho actual de la pantalla es menor o igual al ancho máximo definido
  if (window.innerWidth <= maxWidth) {
    setActive('active');
  }
  
  const out = () => {
    // Para realizar el logout
    localStorage.removeItem('registroExitoso');
    window.location.replace('/Admin/login');

  } 

  
  const clickMenu = () => {
    localStorage.removeItem('Admin')
    window.location.replace('/' + usuario.usuarioadmi.local)
  };
    return(
      <aside id="sidebar" className={active === 'active' ? 'sidebar active' : 'sidebar'}>
    
      <ul className="sidebar-nav" id="sidebar-nav">
      
        <li className="nav-item">
          {/* <Link to="/Admin/mesas"  className="nav-link ">
            <i className="bi bi-grid"></i>
            <span>Mesas</span>
          </Link> */}
          <Link to="/Admin"  className="nav-link ">
            <i className="bi bi-grid"></i>
            <span>Cetral de Pedidos</span>
          </Link>
        </li> 
     <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" >
            <i className="bi bi-layout-text-window-reverse"></i><span>Detalles</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="tables-nav"  className= {active === 'active' ? 'nav-content collapse centerGrid' : 'nav-content collapse'} data-bs-parent="#sidebar-nav">
            <li>
              <Link to='/Admin/productos'>
                <i className="bi bi-circle"></i><span>Productos</span>
              </Link>
            </li>
            <li>
              <Link to='/Admin/meseros'>
                <i className="bi bi-circle"></i><span>Meseros</span>
              </Link>
            </li>
            <li>
              <Link to='/Admin/categorias'>
                <i className="bi bi-circle"></i><span>Categorias</span>
              </Link>
            </li>
            <li>
              <Link to='/Admin/promociones'>
                <i className="bi bi-circle"></i><span>Promociones</span>
              </Link>
            </li>
            {/* <li>
            <Link to='/Admin/usuarios'>
                <i className="bi bi-circle"></i><span>Detalles Usuarios</span>
              </Link>
            </li> */}
          </ul>
        </li> 
      
      
        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" >
            <i className="bi bi-journal-text"></i><span>Altas</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="forms-nav" className= {active === 'active' ? 'nav-content collapse centerGrid' : 'nav-content collapse'}   data-bs-parent="#sidebar-nav">
            <li>
              <Link to="/Admin/productoalta">
                <i className="bi bi-circle"></i><span>Alta Producto</span>
              </Link>
            </li>
            <li>
              <Link to="/Admin/meseroalta">
                <i className="bi bi-circle"></i><span>Alta Mozos</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/Admin/clientealta">
                <i className="bi bi-circle"></i><span>Alta Cliente</span>
              </Link>
            </li> */}
            <li>
              <Link to="/Admin/mesaalta">
                <i className="bi bi-circle"></i><span>Alta Mesa</span>
              </Link>
            </li>
            <li>
              <Link to="/Admin/promocionalta">
                <i className="bi bi-circle"></i><span>Alta Promoción</span>
              </Link>
            </li>
            <li>
              <Link to="/Admin/insumoalta">
                <i className="bi bi-circle"></i><span>Alta Insumo</span>
              </Link>
            </li>
            <li>
              <Link to="/Admin/proveedoralta">
                <i className="bi bi-circle"></i><span>Alta Proveedor</span>
              </Link>
            </li>
          </ul>
        </li> 
      
    
         
         {/* <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" >
            <i className="bi bi-menu-button-wide"></i><span>Datos</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li>
              <a>
                <i className="bi bi-circle"></i><span>Alerts</span>
              </a>
            </li>
           
          </ul>
        </li>    
      
        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" >
            <i className="bi bi-gem"></i><span>Premiun</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="icons-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li>
              <a >
                <i className="bi bi-circle"></i><span>Bootstrap Icons</span>
              </a>
            </li>
            <li>
              <a >
                <i className="bi bi-circle"></i><span>Remix Icons</span>
              </a>
            </li>
            <li>
              <a >
                <i className="bi bi-circle"></i><span>Boxicons</span>
              </a>
            </li>
          </ul>
        </li> 

       <li className="nav-heading">Configuración</li> */}
        {/* 
        <li className="nav-item">
          <a className="nav-link collapsed" >
            <i className="bi bi-person"></i>
            <span>Profile</span>
          </a>
        </li> 
      
      <li className="nav-item">
          <a className="nav-link collapsed" >
            <i className="bi bi-question-circle"></i>
            <span>F.A.Q</span>
          </a>
        </li>  
           <li className="nav-item">

          <Link to="Admin/registro" className="nav-link collapsed">
         
            <i className="bi bi-card-list"></i>
            <span>Registro</span>
          </Link>
        </li> 
      
        */}
      
    
        <li className="nav-item">
        <Link to="/Admin/Caja" className="nav-link collapsed">
          <i class="bi bi-file-spreadsheet"></i>
            <span>Caja</span>
          </Link>
        </li> 
        
        <li className="nav-item">
          <a className="nav-link collapsed"    data-bs-toggle="modal"
                data-bs-target="#exampleModal">

          <i className="bi bi-clipboard-check"></i>
            <span>Mi Menú</span>
          </a>
        </li> 

     

        <li className="nav-item">
          <a className="nav-link collapsed"   href={whatsappLink} target="_blank">
            <i className="bi bi-envelope"></i>
            <span>Contacto</span>
          </a>
        </li> 
     
        <li className="nav-item" onClick={out}>
        <Link to="Admin/login" className="nav-link collapsed">
            <i className="bi bi-box-arrow-in-right"></i>
            <span>Salir</span>
          </Link>
        </li> 
        {/* <li className="nav-item">
          <a className="nav-link collapsed">
            <i className="bi bi-dash-circle"></i>
            <span>Error 404</span>
          </a>
        </li>
      
        <li className="nav-item">
          <a className="nav-link collapsed" >
            <i className="bi bi-file-earmark"></i>
            <span>Blank</span>
          </a>
        </li>
       */}
      </ul>
      
      
      
      </aside>
 )
}